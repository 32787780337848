const quotes = [
  "Think of PN as 1,000 PhDs, always on call.",
  "Turn your investments into uniform ROI.",
  "We torture the data, make it confess to anything you need.",
  "Think of PN as your lens to the future—sharpening your focus on what’s ahead.",
  "Data speaks volumes. PN translates.",
  "Tailor every report section to your exact needs—no compromises.",
  "Data that explains itself—because clarity matters.",
  "Transparency you can trust, down to the last detail.",
];

export default quotes;