const industries = [
  "Deep Tech",
  "Artificial Intelligence & Machine Learning",
  "Fintech & Insurtech",
  "Blockchain & Web 3.0 Technologies",
  "Biotech & Healthtech",
  "Cleantech & Sustainability",
  "Robotics & Automation",
  "Space Technology",
  "Cybersecurity",
  "Quantum Computing",
  "Augmented Reality (AR) & Virtual Reality (VR)",
  "Property Technology",
  "EdTech (Educational Technology)",
  "FoodTech & AgriTech",
  "E-commerce & Direct-to-Consumer (DTC)",
  "Gaming & Esports",
  "MediaTech",
  "Mobility & Transportation Technology",
  "EnergyTech",
  "LegalTech",
  "Marketing Technology",
  "Government Technology",
  "ClimateTech",
  "Wearables & Internet of Things (IoT)",
  "Personalized Medicine & Genomics",
  "5G & Telecommunications",
  "Digital Health & Telemedicine",
  "Digital Infrastructure (Cloud & Edge Computing)",
  "Enterprise SaaS",
  "Other"
];

export default industries;
