export const roles = [
  "Admin",
  // "Analyst",
  // "Associate",
  // "C-level",
  // "IP Manager",
  // "Patent Attorney",
  // "Principal",
  // "Founder",
  // "Investor",
  // "Vice President (VP)",
  // "Partner",
  // "Manager",
  // "Managing Partner",
  // "Venture Partner",
  // "Advisor",
  // "Legal Counsel",
  // "Other", // This will trigger the "Other" input field
];
